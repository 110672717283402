<template>
  <nav v-if="navigationCollection && personalCollection">
  <!-- Navbar -->
  <v-app-bar
    app
    color="grey darken-4"
    dark
    height="60px"
  >
    <!-- Toolbar Title -->
    <v-toolbar-title class="d-flex align-center">
      <div class="hidden-sm-and-down name-container">
        <span class="first-name">{{ personalCollection.firstName }}</span>
        <span class="last-name">{{ personalCollection.lastName }}</span>
      </div>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- Navigation Links -->
    <div class="d-flex align-center">
      <template v-for="(link, index) in navigationCollection.links">
        <router-link
          v-if="link.isAllowed"
          :key="index"
          :to="link.link"
          class="mx-5"
        >
        {{ link.name }}
        </router-link>
      </template>
    </div>

    <!-- Social Icons -->
    <div class="d-flex align-center ml-4">
      <div v-for="(social, index) in navigationCollection.socials" :key="index">
        <v-btn
          v-if="social.isAllowed"
          :key="index"
          icon
          target="_blank"
          :href="social.url"
        >
            <v-icon large>{{ social.icon }}</v-icon>
          </v-btn>
      </div>
    </div>
  </v-app-bar>
</nav>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';

export default {
  name: 'Navbar',

  data() {
    return {
      navigationCollectionRaw: null,
      personalCollectionRaw: null,
    };
  },

  computed: {
    navigationCollection() {
      return (this.navigationCollectionRaw && this.navigationCollectionRaw.results)
        ? this.navigationCollectionRaw.results[0]
        : null;
    },

    personalCollection() {
      if (this.personalCollectionRaw && this.personalCollectionRaw.results) {
        return this.personalCollectionRaw.results[0];
      }
      return null;
    },
  },

  async created() {
    await this.subToCollection();
  },

  destroyed() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.navigationCollectionRaw = await firebaseGetCollection('navigation');
      this.personalCollectionRaw = await firebaseGetCollection('personal');
    },

    unSubToCollection() {
      this.navigationCollectionRaw = null;
      this.personalCollectionRaw = null;
    },
  },
};
</script>

<style scoped>
  .name-container {
    font-family: Arial, sans-serif; /* Adjust as per your font preference */
    font-size: 24px; /* Adjust the size as per your need */
    display: flex;
    align-items: center; /* Vertically centers the text */
  }

  .first-name {
    display: inline-block; /* Allows border properties to apply correctly */
    font-family: Arial, sans-serif; /* Ensure font consistency */
    font-size: 24px; /* Adjust as needed */
    border-bottom: 3px solid #5078FF; /* Adds the underline */
    border-bottom-left-radius: 1px; /* Rounds the left end */
    border-bottom-right-radius: 1px; /* Rounds the right end */
  }

  .last-name {
    margin-left: 10px; /* Adjust the spacing between the first and last name */
    font-size: 24px; /* Ensure consistency */
  }

  .nav-link {
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit color from parent */
  }
</style>
