import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyC_O7kgl1S7K44yeBcRS9DmxklmwPrALGA',
  authDomain: 'portfolio-6f898.firebaseapp.com',
  projectId: 'portfolio-6f898',
  storageBucket: 'portfolio-6f898.appspot.com',
  messagingSenderId: '362900498647',
  appId: '1:362900498647:web:a3a8832cec10c46709e892',
};

// Init firebase
firebase.initializeApp(firebaseConfig);

// Init auth
const projectAuth = firebase.auth();

// Init firestore service (can invoke projectFirestore as a function now (like db.conn() in .net))
const projectFirestore = firebase.firestore();

// Init timestamps (can invoke timestamp as a function now)
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectAuth, projectFirestore, timestamp };
